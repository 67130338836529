<template>
    <v-card v-if="isLoyaltyTypeAllowEarn" class="gst-bonus-points-cart-checkout-confirmation mx-auto gst-bp u-box-shadow pa-0">
        <ImagePublicAssets class="gst-bp__image align-center u-height-100" :path-key="imageSrc" :max-height="85">
            <v-card-text class="gst-bp__headline text-center pa-0 d-flex flex-column justify-center u-height-100">
                <p class="tertiary--text font-weight-medium mb-0">
                    {{ $t("title") }}
                </p>
                <div class="d-flex justify-center align-center">
                    <h4 class="ml-4 font-weight-black quaternary--text d-inline-block">
                        {{ $t("pointWithCount", {count: points, formattedCount: pointsFormated} ).toLowerCase() }}
                    </h4>
                    <BaseTooltip
                        top
                        :open-on-click="!$vuetify.breakpoint.mdAndUp"
                        :open-on-hover="$vuetify.breakpoint.mdAndUp"
                        class-content="gst-bp__tooltip gst-base-tooltip"
                        class-activator="d-flex align-center"
                        :text="$t('tooltip')">
                        <BaseIcon symbol-id="icons--help" class="gst-bp__tooltip-icon" />
                    </BaseTooltip>
                </div>
            </v-card-text>
        </ImagePublicAssets>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import ImagePublicAssets from '@core/shared/components/images/ImagePublicAssets.vue';

    export default {
        name: 'BonusPointsCardCheckoutConfirmation',
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.bonusPoints.bonusPointsCartCheckoutConfirmation'
        },
        components: {
            BaseTooltip,
            BaseIcon,
            ImagePublicAssets
        },
        props: {
            points: {
                type: Number,
                required: true,
                default: 0
            },
            currency: {
                type: String,
                required: true,
                default: ''
            },
            isLoyaltyCurrencyTypeMoney: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'isLoyaltyTypeAllowEarn': 'isLoyaltyTypeAllowEarn'
            } ),
            imageSrc() {
                return this.$vuetify.breakpoint.xsOnly ? 'BONUS_POINTS.MOBILE.CHECKOUT_CONFIRMATION' : 'BONUS_POINTS.DESKTOP.CHECKOUT_CONFIRMATION';
            },
            pointsFormated( ) {
                return this.$options.filters.bonusPoints( this.points );
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-bp {
        height: 84px;
        padding: 10px 0;
        overflow: hidden;

        h4 {
            font-weight: 700;
        }

        &.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
            border-radius: border-radius( 'm' ) !important;
        }

        .gst-bp__headline > * {
            vertical-align: middle;
        }

        .gst-bp__circle-l,
        .gst-bp__circle-r {
            position: absolute;
        }

        .gst-bp__circle-l--one {
            top: -60px;
            left: -60px;
        }

        .gst-bp__circle-l--two {
            top: 45px;
            left: 65px;
        }

        .gst-bp__circle-l--three {
            left: 20px;
            bottom: -30px;
        }

        .gst-bp__circle-r--one {
            top: -23px;
            right: -23px;
        }

        .gst-bp__circle-r--two {
            top: 23px;
            right: 49px;
        }

        .gst-bp__circle-r--three {
            right: -30px;
            bottom: -80px;
        }
    }
</style>
<style lang="scss">
    @import "@scssVariables";

    .gst-bp__tooltip-icon {
        outline: none;

        ::v-deep .gst-svg-icon {
            fill: theme-color( 'senary' );
            transition: all 0.5s ease;
        }
    }

    .gst-bp__tooltip-icon:hover {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }

    .gst-bp__tooltip.v-tooltip__content {
        line-height: line-height( 'xxs' );
        padding: theme-spacing( 2 ) !important;
        background: theme-color( 'tertiary' ) !important;
        font-size: font-size( "xxs" );
    }
</style>
