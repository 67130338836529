import { render, staticRenderFns } from "./TheOrderConfirmation.vue?vue&type=template&id=6f34f86e&scoped=true&CORE"
import script from "./TheOrderConfirmation.vue?vue&type=script&lang=js&CORE"
export * from "./TheOrderConfirmation.vue?vue&type=script&lang=js&CORE"
import style0 from "./TheOrderConfirmation.vue?vue&type=style&index=0&id=6f34f86e&lang=scss&scoped=true&CORE"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f34f86e",
  null
  
)

export default component.exports