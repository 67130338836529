<template>
    <div class="gst-checkout-confirmation d-flex flex-column py-2">
        <header class="gst-checkout-confirmation__header d-flex flex-row align-center justify-center">
            <BaseIcon symbol-id="icons--back_arrow" class="u-mouse-pointer gst-checkout-confirmation__icon-back" @click="$router.push( { name: 'home' })" />
            <TenantLogo :with-redirect="false" class="gst-checkout-confirmation__icon-logo" />
        </header>
        <div class="px-4 u-overflow-scroll-y">
            <div class="gst-checkout-confirmation__container">
                <p class="text-center mt-6 mt-md-10 mb-4 gst-checkout-confirmation__title">
                    {{ $t('title') }}
                </p>
                <!-- eslint-disable-next-line vue/no-v-html  -->
                <h6 class="text-center tertiary--text gst-checkout-confirmation__subtitle mb-4 px-5" v-html="subtitle"></h6>
                <BonusPointsCartCheckoutConfirmation
                    v-if="bonusPointsEarn"
                    :points="bonusPointsEarn"
                    :currency="loyaltyCurrency || currency"
                    :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                    class="mt-md-6 mb-4" />
                <ticket-preview-image class="mb-4"
                    :image-props="{
                        src: event.imageUrl,
                        width: '100%',
                        height: '90px',
                        gradient: gradient
                    }"
                    :text="event.name" />
                <div class="gst-checkout-confirmation__event-details d-flex flex-column">
                    <ticket-date-icon :start-date-time="event.startDateTime" :end-date-time="event.endDateTime" class="mb-2" />
                    <ticket-location-icon
                        :venue="venueLocation"
                        class="mb-4" />
                </div>
                <ticket-card-variant-two
                    :ticket="ticketLocation"
                    :outlined="true"
                    :event="event"
                    class="u-width-100 u-background-transparent mb-4 gst-checkout-confirmation__ticket-card" />
                <OrderSummary
                    :cart-collection="eventTicket"
                    :order="order"
                    :payment-model="paymentModel"
                    :shipping-model="{ options: [ shippingOption ] }"
                    is-order-confirmation />
            </div>
        </div>
        <div v-if="isElectronicTicketItem" class="gst-checkout-confirmation__container pt-6">
            <BaseButton
                :style="{width: '100%'}"
                :placeholder="$t('buttons.download')"
                :href="downloadUrl" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { isElectronicTicketItem as shippingOptionsUtilsIsElectronicTicketItem } from '@core/utils/shippingOptionsUtils';
    import productsConstants from '@core/utils/constants/products';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import TicketDateIcon from '@core/shared/components/ticket/TicketDateIcon.vue';
    import TicketLocationIcon from '@core/shared/components/ticket/TicketLocationIcon.vue';
    import TicketPreviewImage from '@core/shared/components/ticket/TicketPreviewImage.vue';
    import TicketCardVariantTwo from '@core/shared/components/ticket/TicketCardVariantTwo.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BonusPointsCartCheckoutConfirmation from '@core/shared/components/bonusPoints/BonusPointsCartCheckoutConfirmation.vue';
    import OrderSummary from './TheCheckoutWizard/OrderSummary';

    export default {
        name: 'CheckoutConfirmation',
        components: {
            OrderSummary,
            BaseIcon,
            BonusPointsCartCheckoutConfirmation,
            TicketDateIcon,
            TicketLocationIcon,
            TicketPreviewImage,
            TicketCardVariantTwo,
            BaseButton,
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theOrderConfirmation'
        },
        data() {
            return {
                gradient: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)',
            };
        },
        computed: {
            ...mapGetters( {
                isLoyaltyCurrencyTypeMoney: 'appTenant/isLoyaltyCurrencyTypeMoney',
                isLoyaltyTypeAllowRedeem: 'appTenant/isLoyaltyTypeAllowRedeem'
            } ),
            routeParams( ) {
                return this.$route.params;
            },
            order( ) {
                return this.routeParams.order;
            },
            paymentModel( ) {
                return this.routeParams.paymentModel;
            },
            shippingOption( ) {
                return this.routeParams.shippingOption;
            },
            bonusPointsEarn( ) {
                return this.order.pointsEarned;
            },
            event() {
                return this.eventTicket.event;
            },
            isElectronicTicketItem( ) {
                return shippingOptionsUtilsIsElectronicTicketItem( this.shippingOption );
            },
            subtitle( ) {
                return this.isElectronicTicketItem ? this.$t( 'subtitle.elecTicket' ) : this.$t( 'subtitle.noElecTicket' );
            },
            currency( ) {
                const { order } = this;
                return order.currency;
            },
            loyaltyCurrency( ) {
                const { order } = this;
                return order.loyaltyCurrency;
            },
            eventTicket( ) {
                return this.order.items
                    .find( item => item.productTypeId === productsConstants.TYPES.TICKET );
            },
            ticketLocation() {
                return {
                    section: this.eventTicket.ticket.section,
                    seats: this.eventTicket.ticket.seats,
                    row: this.eventTicket.ticket.row,
                    startSeatNumber: this.eventTicket.ticket.startSeatNumber,
                    endSeatNumber: this.eventTicket.ticket.endSeatNumber,
                };
            },
            venueLocation( ) {
                const { event } = this.eventTicket;
                const { address } = event;
                
                return {
                    name:          event.venueName,
                    street:        address.address,
                    city:          address.cityName,
                    stateCode:     address.stateCode,
                    countryCode:   address.countryCode
                };
            },
            downloadUrl( ) {
                return this.eventTicket.redemptionUrl;
            }
        },
        mounted( ) {
            if ( !this.order ) {
                this.$router.back( );
            }
        }
    };
</script>

<style lang="scss" scoped>

    @import "@scssVariables";
    @import "@scssMixins";

    .gst-checkout-confirmation {
        @include viewport-height( 100, !important );
    }

    .gst-checkout-confirmation__header {
        position: relative;
        height: 56px;
        border-bottom: 1px solid  theme-color( 'quinary' );
    }

    .gst-checkout-confirmation__icon-back {
        position: absolute;
        left: 20px;
    }

    .gst-checkout-confirmation__title {
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxxxl' );
        font-weight: font-weight( 'bold' );
    }

    .gst-checkout-confirmation__subtitle {
        line-height: line-height( 'xxxl' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
    }

    .gst-checkout-confirmation__ticket-card {
        padding: theme-spacing( 3 );
    }

    .gst-checkout-confirmation__container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        max-width: 600px;
    }
</style>
